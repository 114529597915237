@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

:root {
  --primary-color: #13213c;
  --secondary-color: #ffb606;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1240px;
  margin: auto;
}

h1 {
  font-size: 3.5rem;
  line-height: 1.2;
}

h2 {
  font-size: 2.4rem;
  line-height: 1.3;
}

h3 {
  font-size: 2rem;
  line-height: 1.2;
}

h5 {
  font-size: 1.1rem;
  line-height: 1.2;
}

p {
  font-size: 1.2rem;
}

.btn {
  padding: 10px 20px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5 ease-in-out;
}

.btn:hover {
  box-shadow: rgb(0 0 0 /5%) 0px 8px 24px;
}
